import React from 'react';
import styled from 'styled-components';
import Image from "gatsby-image"
import { Link } from "gatsby"
import Menu from './../Menu/index';
import MyLink from './../Link/index';
import {log} from './../../utilities/measure.js';

import IconButton from '../IconButton/index';

const Hero = (props) => {
    log(`Hero props`, props);
    let {title, img, onBack} = props;
    return <HeroView key="hero" className="game-ui">
                <NavBar>
                    <Menu />
                    {onBack && <IconButton id="back-button" icon="icon-circle-right" rotate="180" onInput={onBack}/>}
                    {img && <Image fixed={img} />}
                    <h1 className="title">Medication Math</h1>
                    <h2>Print and Play Card Game</h2>
                    <nav>
                        <MyLink url="/" activeClassName="active" text="Questions" />
                        <MyLink url="/answer" activeClassName="active" text="Answers" />
                    </nav>
                </NavBar>
                {props.children}
            </HeroView>
}

export default Hero;

const NavBar = styled.nav`
    position:relative;
    text-align: center;
    overflow: hidden;
    #back-button{
        position: absolute;
        top:0px;
        left:0px;
    }
    .title{
        display: inline-block;
        padding: 10px 0px 0px 0px;
        color: ${props => props.theme.textL};
    }
    h2{
        color: ${props => props.theme.textL};
    }
    nav{
        display:flex;
        flex-direction:row;
        .animation-link{
            flex: 1;
        }
    }
`
const HeroView = styled.section`
    position: relative;
    background-color: ${props => props.theme.primary};
`