import React from 'react';
import {ThemeProvider} from 'styled-components';
import Hero from './../components/Hero/index';
import CookieConsent from "react-cookie-consent";
import {AnimatePresence, motion} from 'framer-motion';
import { Link } from "gatsby";

export const theme = {
    text: 'black',
    primary: '#FF0005',
    primaryD: '#a30003',
    primaryL: '#fd6163',
    secondary: '#FFF28C',
    text: '#121212',
    textL: '#ffffffff',
    bg: 'white',
    neutral: '#3D3D3D',
    neutralL: '#c5c5c5',
    remS: '0.8rem',
    remM:'1rem',
    animS: '0.3s',
    animM: '0.8s',
    animL: '1.2s',
    widthS: '320px',
    widthM: '480px',
    widthL: '760px',
    getColor: (theme, color, fallback) => {
        if(theme[color]){
            return theme[color];
        }else if(color && color !== true){
            return color;
        }else{
            if(fallback){
                return theme[fallback] || fallback;
            }
            return theme.text;
        }
    },
    getFontSize: (theme, size, fallback) => {
        if(theme[size]){
            return theme[size];
        }else if(size && size !== true){
            return size + 'em';
        }else{
            if(fallback) return theme[fallback]?theme[fallback]:fallback+'em';
            return theme.remS;
        }
    }
}


const Shell = ({children}) => {
    return <ThemeProvider theme={theme}>
        <Hero />
        <AnimatePresence exitBeforeEnter>
            {children}
        </AnimatePresence>
        <CookieConsent
            location="bottom"
            buttonText="Okay"
            cookieName="myAwesomeCookieName2"
            style={{ background: theme.neutral }}
            buttonStyle={{ backgroundColor: theme.primary, color: theme.textL, fontSize: "16px" }}
            expires={150}
            >
            This website uses cookies to improve site features. You can read more about this 
            <Link
                to={'/privacy'}
                activeClassName="active"
                className="animation-link inline-animation-link"
            >
                 here
            </Link>
        </CookieConsent>
    </ThemeProvider>
}


export default Shell;