import React from 'react';
import styled from 'styled-components';
import {log} from './../../utilities/measure.js';
import { Link } from "gatsby";
import './Link.css';
/**
 * Make link with hover animation
 */

 const LinkView = ({url, text, direction, aniColor, color, bgColor}) => {
    return <Link
                to={url}
                activeClassName="active"
                className="animation-link"
            >
                <Word color={color || 'white'} bgColor={bgColor || 'primaryL'}>
                    <span className="link-bg"/>
                    <h5 className="link-word">{text}</h5>
                </Word>
            </Link>
 }

 export default LinkView;

 const Word = styled.span`
    display:inline-block;
    position: relative;
    height:100%;
    width:100%;
    .link-word{
        position: relative;
        padding: 10px;
        text-align: center;
        color: ${props => props.color};
        margin:0;
    }
    .link-bg{
        position: absolute;
        top:0px;
        left: 0px;
        right:0px;
        bottom:0px;
        background-color: ${props => props.theme.getColor(props.theme, props.bgColor)};
        opacity:0.75;
        transition: opacity ${props => props.theme.animS} ease;
    }
    
    &:hover .link-bg{
        opacity: 1;
    }
 `