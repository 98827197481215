import React, {useState} from 'react';
import styled from 'styled-components';
import IconButton from './../IconButton/index';
import MyLink from './../Link/index';
import {log, sendError} from './../../utilities/measure.js';

const Menu = (props) => {
    const [open, setOpen] = useState(false);
   
    const toggleOpen = () => {
        setOpen(!open)
    };
    return <PopUp className="game-ui">
        <IconButton id="popup-btn" icon="icon-circle-right" color="textL" rotate={open?180:0} onInput={toggleOpen} />
        <nav id="side-menu" className={`${open?'isOpen':''}`}>
            <MyLink url="/privacy" bgColor="white" color="red" activeClassName="active" text="Privacy" />
            <MyLink url="/about" text="About" bgColor="white" color="red" activeClassName="active" />
        </nav>
    </PopUp>
}

export default Menu;

const PopUp = styled.div`
    position: absolute;
    top:0px;
    right:0px;
    z-index: 3;
    min-width: 100px;
    text-align:right;
    #popup-btn{

    }
    #side-menu{
        position: absolute;
        background-color: green;
        transform: translateX(100px);
        transition: transform 1s ease;
        width:100%;
        background-color: ${props => props.theme.textL};
        display:flex;
        flex-direction: column;
    }
    #side-menu.isOpen{
        transform: translateX(0px);
    }
    .animation-link{
        text-decoration: none;
    }

`