export const log = (text, object, type) => {
    switch(type){
        default:
            if(process.env.GATSBY_SITE_URL === 'localhost:8000'){
                console.log(text, object);
            }
            
    }
}

export const sendError = (error) => {
    console.error(error);
}